// Images
const img_best_cryp_c = "articles/best-cryp-c.webp";
const img_best_cryp = "articles/best-cryp.webp";
const img_best_plat_c = "articles/best-plat-c.webp";
const img_best_plat = "articles/best-plat.webp";
const img_best_wall_c = "articles/best-wall-c.webp";
const img_best_wall = "articles/best-wall.webp";
const img_comp_wall_c = "articles/comp-wall.webp";
const img_comp_wall = "articles/comp-wall.webp";
const img_earn_mone_c = "articles/earn-mone-c.webp";
const img_earn_mone = "articles/earn-mone.webp";
const img_pres_cryp_c = "articles/guide-debutants-cryptomonnaies.webp";
const img_pres_cryp = "articles/guide-debutants-cryptomonnaies.webp";
const img_pres_wall_c = "articles/pres-wall-c.webp";
const img_pres_wall = "articles/pres-wall.webp";
const img_cree_bina_c = "articles/cree-bina-c.webp";
const img_cree_bina = "articles/cree-bina.webp";

import { article as best_cryp } from "./content/best-cryp.js";
import { article as best_plat } from "./content/best-plat.js";
import { article as best_wall } from "./content/best-wall.js";
import { article as comp_wall } from "./content/comp-wall.js";
import { article as earn_mone } from "./content/earn-mone.js";
import { article as pres_cryp } from "./content/pres-cryp.js";
import { article as pres_wall } from "./content/pres-wall.js";
import { article as cree_bina } from "./content/cree-bina.js";
import { article as buy_cryp } from "./content/buy-cryp.js";

export const articles = {
    "home": {
      img: "la-meilleure-crypto.webp",
    },
    "pres-cryp": {
      image: img_pres_cryp,
      image_c: img_pres_cryp_c,
      content: pres_cryp,
      updateDate: "06.10.2024",
      img: "articles/guide-debutants-cryptomonnaies.webp",
    },
    "best-cryp": {
      image: img_best_cryp,
      image_c: img_best_cryp_c,
      content: best_cryp,
    },
    "buy-cryp": {
      image: "articles/buy-cryp.webp",
      image_c: "articles/buy-cryp-c.webp",
      content: buy_cryp,
      updateDate: "13.11.2024",
    },
    "pres-wall": {
      image: img_pres_wall,
      image_c: img_pres_wall_c,
      content: pres_wall,
    },
    "best-wall": {
      image: img_best_wall,
      image_c: img_best_wall_c,
      content: best_wall,
    },
    "comp-wall": {
      image: img_comp_wall,
      image_c: img_comp_wall_c,
      content: comp_wall,
      banner: true,
      updateDate: "06.10.2024",
    },
    "best-plat": {
      image: img_best_plat,
      image_c: img_best_plat_c,
      content: best_plat,
      banner: true,
    },
    "earn-mone": {
      image: img_earn_mone,
      image_c: img_earn_mone_c,
      content: earn_mone,
    },
    "cree-bina": {
      image: img_cree_bina,
      image_c: img_cree_bina_c,
      content: cree_bina,
      updateDate: "03.10.2024",
    },
  };


  