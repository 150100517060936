import { gallery } from "data/data";
import { getImage } from "lib";
import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ImageCarousel = () => {
    // Utilise `getImage` pour transformer les noms de fichiers dans `gallery`
    const transformedGallery = gallery.map((image) => ({
      original: getImage(image.original),
      thumbnail: getImage(image.thumbnail, false, 150),
    }));
  
    return (
      <div style={{ maxWidth: "100%" }}>
        <ImageGallery
          items={transformedGallery}
          showThumbnails={true}
          showFullscreenButton={false}
          showPlayButton={true}
        />
      </div>
    );
  };

export default ImageCarousel;
