import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Rating from "@mui/material/Rating";
import Switch from "@mui/material/Switch";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKInput from "components/MKInput";
import MKTypography from "components/MKTypography";
import config from "config";
import { callbackAfterRecaptcha, encodeFormData, validateEmail } from "lib";
import { useRecaptcha } from "lib/useRecaptcha";
import propTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const ContactForm = ({ avis, titleAlign }) => {
  const { t } = useTranslation();

  // State for form inputs and errors
  const [formName, setFormName] = useState("contact");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const [consent, setConsent] = useState(true);
  const [rating, setRating] = useState(0);


  useEffect(() => {
    setSuccessMessage(""); // Clear success message when the form type changes
    setFormName(avis ? "avis" : "contact");
  }, [avis]);

  useRecaptcha();

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = t("contact.msg_err_name_required");
    if (!email) {
      newErrors.email = t("contact.msg_err_email_requis");
    } else if (!validateEmail(email)) {
      newErrors.email = t("contact.msg_err_email");
    }
    if (!message || message.length < 20) {
      newErrors.message = t("contact.msg_err_min_car");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendForm = () => {
    if (validate()) {
      const templateParams = {
        name,
        email,
        message,
      };

      const avisParam = {
        consent,
        rating,
      };

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({ "form-name": formName, ...{ ...templateParams, ...(avis && avisParam) } }),
      })
        .then(() => {
          // console.log("Formulaire soumis avec succès !");
          setSuccessMessage(t("contact.msg_sent"));
          setName("");
          setEmail("");
          setMessage("");
          // setConsent(true);
          setRating(0);
          setErrors({});
        })
        .catch((error) => {
          console.log("Erreur de soumission : " + error);
          setErrors({ global: t("contact.msg_err") });
        });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    callbackAfterRecaptcha(() => sendForm());
  };
  // console.log("err:", errors  )

  const toggleConsent = () => setConsent(!consent);

  const title = avis ? t("contact.title_avis") : t("contact.title");
  const description = avis
    ? t("contact.message_avis")
    : t("contact.message", { email: config.emailAdress });

  return (
    <>
      <MKBox
        variant="gradient"
        bgColor="info"
        coloredShadow="info"
        borderRadius="lg"
        textAlign={titleAlign}
        p={2}
        mx={2}
        mt={-3}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <MKTypography variant="h1" fontSize="1.875rem" color="white">
          {title}
        </MKTypography>
      </MKBox>
      <MKBox p={3}>
        <MKTypography variant="body2" color="text" mb={3}>
          {description}
        </MKTypography>
        {/* noValidate = no validate fields by browser */}
        <form width="100%" onSubmit={handleSubmit} noValidate>
          {/* Champ caché nécessaire pour Netlify */}
          <input type="hidden" name="form-name" value={formName} />

          {avis && <input type="hidden" name="rating" value={rating} />}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <MKInput
                // variant="standard"
                label={t("contact.name")}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="name"
                value={name}
                inputProps={{ maxLength: 50 }}
                onChange={(e) => setName(e.target.value)}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MKInput
                type="email"
                // variant="standard"
                label={avis ? t("contact.email_avis") : t("contact.email")}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="email"
                value={email}
                inputProps={{ maxLength: 100 }}
                onChange={(e) => setEmail(e.target.value)}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <MKInput
                // variant="standard"
                label={avis ? t("contact.testimonial") : t("contact.question")}
                placeholder={t("contact.placeholder")}
                InputLabelProps={{ shrink: true }}
                multiline
                fullWidth
                rows={6}
                name="message"
                value={message}
                inputProps={{ maxLength: 250 }}
                onChange={(e) => setMessage(e.target.value)}
                error={Boolean(errors.message)}
                helperText={errors.message}
              />
            </Grid>
            {avis && (
              <>
                <Grid container item xs={12} justifyContent="center" mx="auto">
                  <MKBox display="flex" alignItems="center">
                    <MKTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      ml={1}
                      sx={{ cursor: "pointer", userSelect: "none", mr: 2 }}
                    >
                      {t("contact.note")}
                    </MKTypography>
                    <Rating
                      value={rating}
                      onChange={(event, newValue) => setRating(newValue)}
                      // sx={{ mb: 2 }}
                    />
                  </MKBox>
                </Grid>
                <Grid container item xs={12} justifyContent="center" mx="auto">
                  <MKBox display="flex" alignItems="center">
                    <Switch checked={consent} onChange={toggleConsent} name="consent" />
                    <MKTypography
                      variant="button"
                      color="text"
                      fontWeight="regular"
                      ml={1}
                      sx={{ cursor: "pointer", userSelect: "none" }}
                      onClick={toggleConsent}
                    >
                      {t("contact.publish_site")}
                    </MKTypography>
                  </MKBox>
                </Grid>
              </>
            )}
          </Grid>

          <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
            <MKButton type="submit" variant="gradient" color="info">
              {avis ? t("contact.button_avis") : t("contact.button")}
            </MKButton>
          </Grid>
          {successMessage && (
            <MKTypography variant="body2" color="success" align="center" mt={2}>
              {successMessage}
            </MKTypography>
          )}
        </form>
      </MKBox>
    </>
  );
};

ContactForm.propTypes = {
  avis: propTypes.bool,
  titleAlign: propTypes.string,
};

ContactForm.defaultProps = {
  avis: false,
  titleAlign: "left",
};

export default ContactForm;
