import { isMobile } from "lib";
import InputField from "./InputField";

const InputPhoneField = ({ ...rest }) => {
  return (
    <InputField
      phoneNumber={!isMobile()}
      type={isMobile() ? "tel" : "text"} // pattern={isMobile() ? "[0-9]*" : undefined} // Applique le pattern uniquement sur mobile
      // inputMode={isMobile() ? "numeric" : undefined} // Utilise le clavier numérique uniquement sur mobile
      //   maxLength={isMobile() ? 4 : undefined}
      {...rest}
    />
  );
};

export default InputPhoneField;
