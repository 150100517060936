import { LinearProgress } from "@mui/material";
import propTypes from "prop-types";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

const SuspenseSpin = ({ children }) => {
  const { t } = useTranslation();
  return <Suspense
    fallback={
      <LinearProgress
        color="info"
        aria-label={t("Chargement...")}
        sx={{ height: '1px', '& .MuiLinearProgress-bar': { height: '1px' } }}
      />
    }
  >
    {children}
  </Suspense>;
};

SuspenseSpin.propTypes = {
  children: propTypes.node,
};

export default SuspenseSpin;
