/* eslint-disable react/prop-types */
/* eslint-disable-next-line react/prop-types */
import react from "react";
import { Trans, useTranslation } from "react-i18next";

// @mui material components
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Icon from "@mui/material/Icon";
// import CircleIcon from '@mui/icons-material/Circle';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { Box, Grid } from "@mui/material";
import ArticleProfile, { articleUpdateDate } from "./ArticleInfo";

import PropTypes from "prop-types";
import Markdown from "react-markdown";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

import DefaultHeader from "components/DefaultHeader";
import ImageCarousel from "components/ImageCarousel";
import config from "config";
import { routes } from "data/data";
import { buildEmailLink, getImage } from "lib";
import { getArticle, getArticleTitle } from "lib/articles";
import SimpleTable from "pages/SimpleTable";
import { Link } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import Affiliate, { affiliates } from "./Affiliate";
import Buy from "./Buy";

function Article({ name, legal }) {
  const { t } = useTranslation();

  const article = getArticle(name);
  const title = getArticleTitle(t, name);
  const description = t(`articles.${name}.summary`);
  
  return (
    <>
      <DefaultNavbar routes={routes} source={name} sticky />
      <DefaultHeader
        title={title}
        bgImage={getImage(article.image)}
        description={description}
        showTitle={!legal}
        subtitle={<ArticleProfile profile="Bruno" name={name} />}
        expanded={article.expanded ?? false}
      >
        <Markdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          // remarkPlugins={[remarkGfm]}
          components={{
            // Map `h1` (`# heading`) to use `h2`s.
            pre: "div",
            // Rewrite tags
            h1(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <MKBox mt={4} mb={3}>
                  <MKTypography variant={`h1`} fontSize="2.4rem" {...rest} />
                </MKBox>
              );
            },
            h2(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <MKBox mt={3} mb={2}>
                  <MKTypography variant={`h2`} fontSize="2rem" {...rest} />
                </MKBox>
              );
            },
            h3(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <MKBox mt={2} mb={1}>
                  <MKTypography variant={`h3`} {...rest} />
                </MKBox>
              );
            },
            h4(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <MKBox mt={2} mb={1}>
                  <MKTypography variant={`h4`} {...rest} />
                </MKBox>
              );
            },
            h6(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <MKTypography variant="body1" fontWeight="light" color="text" {...rest}>
                  <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                </MKTypography>
              );
            },
            a(props) {
              const { node, ...rest } = props;
              return <Link to={rest.href}>{rest.children}</Link>;
            },
            span: ({ node, ...props }) => {
              if (props.className === "email") {
                return buildEmailLink(config.emailAdress);
              }
              if (props.className === "name") {
                return config.name;
              }
              if (props.className === "address") {
                return config.address;
              }
              if (props.className === "pseudo") {
                return config.author;
              }
              if (props.className === "space1") {
                return <Box sx={{ m: 2 }} />;
              }
              if (props.className === "SimpleTable") {
                return <SimpleTable />;
              }
              if (props.className === "ImageCarousel") {
                return <ImageCarousel />;
              }

              if (
                [
                  "WhiteBookBitcoin",
                  "Ledger",
                  "LedgerS",
                  "Coldcard",
                  "KeepKey",
                  "TrezorT",
                  "Coldcard",
                ].includes(props.className)
              ) {
                return <Buy name={props.className} />;
              }
              if (props.className === "Binance") {
                return (
                  <>
                    <Affiliate name="Binance" simple />
                    <MKBox mt={1} fontSize="0.875rem" color="textSecondary">
                      <Trans
                        i18nKey="affil.description"
                        values={{
                          name: props.className,
                          percent: affiliates[props.className].percent,
                        }}
                      />
                    </MKBox>
                  </>
                );
              }
              if (props.className === "BinanceCard") {
                return <Affiliate name="Binance" />;
              }
              if (
                props.className === "Coinbase" ||
                props.className === "Kraken" ||
                props.className === "KuCoin" ||
                props.className === "Gemini"
              ) {
                return <Affiliate noreduction name={props.className} />;
              }
              return <span {...props} />;
            },
            code(props) {
              const { children, className, node, ...rest } = props;
              const matchBullet = /language-bullet/.exec(className || "");
              const matchList = /language-list/.exec(className || "");
              const matchPros = /language-pros/.exec(className || "");
              const matchCons = /language-cons/.exec(className || "");
              // console.log("code>>>", className)
              const IconComponent = matchPros
                ? "check"
                : matchCons
                ? "close"
                : matchBullet
                ? NavigateNextIcon
                : ArrowForwardIcon;
              const color = matchPros ? "success" : matchCons ? "error" : "text";
              // eslint-disable-next-line react/prop-types
              const list = children.split(/[£]/).map((element, i) => (
                <MKTypography
                  variant="body1"
                  fontWeight="light"
                  color="text"
                  key={`code-list-${i}`}
                  {...rest}
                  className={className}
                >
                  <Icon color={color} sx={{ fontWeight: "bold" }}>
                    {typeof IconComponent === "string" ? IconComponent : <IconComponent />}
                  </Icon>
                  &nbsp;&nbsp;
                  <Markdown
                    components={{
                      p: react.Fragment, // Supprime les balises <p> générées
                    }}
                  >
                    {element.trim()}
                  </Markdown>
                </MKTypography>
              ));
              return matchList || matchBullet || matchPros || matchCons ? (
                list
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              );
            },
            p(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <MKTypography
                  variant="body1"
                  fontWeight="light"
                  color="text"
                  sx={{ my: 3 }}
                  {...rest}
                />
              );
            },
            ul(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return <ul className="MuiList-root MuiList-padding css-1ye9zee" {...rest} />;
            },
            li(props) {
              // eslint-disable-next-line react/prop-types
              const { node, ...rest } = props;
              return (
                <li className="MuiListItem-root MuiListItem-gutters css-ykjcgo">
                  <MKTypography variant="body1" fontWeight="light" color="text" {...rest} />
                </li>
              );
            },
          }}
        >
          {article.content}
        </Markdown>
        <Box sx={{ m: 4 }} />
        {legal ? (
          <>
            <Grid container spacing={3} mb={3}>
              <Grid item>
                <MKTypography component="span" variant="body2">
                  {t("updatedAt")} {articleUpdateDate(name)}
                </MKTypography>
              </Grid>
            </Grid>
          </>
        ) : (
          config.affiliate && <Affiliate name="Binance" />
        )}
      </DefaultHeader>
    </>
  );
}

Article.propTypes = {
  children: PropTypes.node,
  legal: PropTypes.bool,
  name: PropTypes.string,
};

Article.defaultProps = {
  legal: false,
};

export default Article;
