export const content = `# Politique de Confidentialité

Nous accordons une grande importance à la confidentialité de vos données personnelles. Cette politique décrit comment nous collectons, utilisons, et protégeons vos informations.

## Données Collectées
Lorsque vous visitez notre blog, nous pouvons collecter les types de données suivants&nbsp;:
- Informations de navigation via des cookies (adresse IP, type de navigateur, etc.).
- Adresse e-mail si vous vous abonnez à notre newsletter.

## Utilisation des Données
Les données collectées sont utilisées pour&nbsp;:
- Améliorer l'expérience utilisateur sur le site.
- Vous envoyer des informations si vous avez souscrit à la newsletter.

## Hébergement des Données
Notre site est hébergé par Netlify, dont les serveurs sont situés aux États-Unis. En utilisant ce site, vous acceptez que vos données soient transférées et stockées aux États-Unis.

## Partage des Données
Vos données ne seront ni vendues, ni partagées à des tiers sans votre consentement, à l'exception des partenaires nécessaires au bon fonctionnement du site (services d'hébergement, outils d'analyse).

## Droits des Utilisateurs
Vous disposez d'un droit d'accès, de rectification, et de suppression de vos données. Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante&nbsp;: <span class="email"></span>

## Cookies
Nous utilisons des cookies pour analyser le trafic et améliorer l'expérience utilisateur. Vous pouvez désactiver les cookies dans les paramètres de votre navigateur, mais cela peut affecter certaines fonctionnalités du site.
`