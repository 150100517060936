import { Box } from "@mui/material";
import config from "config";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import { CardButton } from "examples/Cards/InfoCards/FilledInfoCard";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

export const affiliates = {
  Binance: {
    url: "https://accounts.binance.com/register?ref=F13PXRPZ",
    percent: "10",
  },
  Coinbase: {
    url: "https://www.coinbase.com/signup",
  },
  Kraken: {
    url: "https://www.kraken.com/fr/sign-up",
  },
  KuCoin: {
    url: "https://www.kucoin.com/ucenter/signup",
  },
  Gemini: {
    url: "https://exchange.gemini.com/register",
  },
};

// const handleGtmClick = (name) => {
//   // Envoyer l'événement de clic à GTM
//   if (name === "Binance") {
//     TagManager.dataLayer({
//       dataLayer: {
//         event: 'binance-click',
//         category: 'Button',
//         action: 'Click',
//         label: name + ' Link Button',
//       },
//     });
//   }
//   window.open(affiliates[name]?.url || "", '_blank', 'noopener');
// }

function Affiliate({ name, simple, noreduction }) {
  const { t } = useTranslation();

  const handleAffiliateLink = () => {
    window.open(affiliates["Binance"].url, "_blank", "noopener noreferrer");
  };
  const action = {
    type: name === "Binance" ? "internal" : "external",
    label: t("affil.action", { name: name }),
    button: true,
    rel: `noopener noreferrer${!noreduction ? ' sponsored' : ''}`,
    route: name === "Binance" ? "/merci-inscription-binance" : affiliates[name]?.url || "",
    onClick: () => (name === "Binance" ? handleAffiliateLink() : null),
  };
  const variant = noreduction ? "contained" : "gradient";
  return (
    <>
      <Box sx={{ m: 2 }} />
      {simple ? (
        <CardButton variant={variant} action={action} color="info" />
      ) : (
        <FilledInfoCard
          variant={variant}
          color="info"
          icon={noreduction ? "" : "flag"}
          title={noreduction ? "" : t("affil.title")}
          description={noreduction ? t("affil.description_simple") : ""}
          descriptionComponent={
            !noreduction && (
              <Trans
                i18nKey="affil.description"
                values={{ name, percent: affiliates[name].percent }}
                components={{ strong: <strong style={{ color: config.customColor }} /> }}
              />
            )
          }
          action={action}
        />
      )}
    </>
  );
}

Affiliate.propTypes = {
  simple: PropTypes.bool,
  noreduction: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

Affiliate.defaultProps = {
  simple: false,
  noreduction: false,
};

export default Affiliate;
