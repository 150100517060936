let articles;
let legaux;
let team;
let footer;
let routes;
let gallery = [];

if (process.env.REACT_APP_SITE === 'petitspas') {
  articles = require('data/petitspas/articles').articles;
  legaux = require('data/petitspas/legaux').legaux
  team = require('data/petitspas/team').team
  footer = require('data/petitspas/footer').footer;
  routes = require('data/petitspas/routes').routes;
  gallery = require('data/petitspas/gallery').data;
} else if (process.env.REACT_APP_SITE === 'ouvertureuape') {
  articles = require('data/ouvertureuape/articles').articles;
  legaux = require('data/ouvertureuape/legaux').legaux
  team = require('data/ouvertureuape/team').team
  footer = require('data/ouvertureuape/footer').footer;
  routes = require('data/ouvertureuape/routes').routes;
} else if (process.env.REACT_APP_SITE === 'maxicashback') {
  articles = require('data/maxicashback/articles').articles;
  legaux = require('data/maxicashback/legaux').legaux
  team = require('data/maxicashback/team').team
  footer = require('data/maxicashback/footer').footer;
  routes = require('data/maxicashback/routes').routes;
} else if (process.env.REACT_APP_SITE === 'meilleurecrypto') {
  articles = require('data/meilleurecrypto/articles').articles;
  legaux = require('data/meilleurecrypto/legaux').legaux;
  footer = require('data/meilleurecrypto/footer').footer;
  routes = require('data/meilleurecrypto/routes').routes;
} else {
  throw new Error('Aucun site valide n\'a été défini.');
}

export { articles, legaux, team, footer, routes, gallery };