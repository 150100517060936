import { content as affiliation } from "./legal/affiliation.js";
import { content as confident } from "./legal/confident.js";
import { content as cookie } from "./legal/cookie.js";
import { content as mention } from "./legal/mention.js";

const img_legal = "legal.webp";

import { content as cgu } from "./legal/cgu.js";


export const legaux = {
    cgu: {
      image: img_legal,
      content: cgu,
    },
    // 'legal': {
    //   "image": img_legal,
    //   "content": legal,
    // },
    affiliation: {
      image: img_legal,
      content: affiliation,
    },
    confident: {
      image: img_legal,
      content: confident,
    },
    cookie: {
      image: img_legal,
      content: cookie,
    },
    mention: {
      image: img_legal,
      content: mention,
    },
  };
  
  