const siteConfig = {
  petitspas: {
    name: "La garderie aux petits pas",
    short_name: "AuxPetitsPas",
    theme_color: "#1A73E8",
    emailAdress: "info@garderieauxpetitspas.ch",
    address: "La Mouniaz Centre, Route du Simplon 21, 1845 Noville",
    mapLink: "https://maps.app.goo.gl/yzQL3kjuBFcb2dPe9",
    phoneNumber: "+41 21 968 11 12",
    natel1Number: "+41 79 670 71 10",
    natel2Number: "+41 79 670 77 25",
    iban: "CH20 0900 0000 6523 8027 0",
    accountNumber: "65-238027-0",
    imagekitId: "o8gtczz93",
    captchaKey: "6LcO_mwqAAAAAOPhrF-6qcLBhfkrw2HMwfNnO1WC",
    logo: "logoc.ico",
    customColor: "#A7C7E7",
    home: "Presentation", 
    showSignupInHome: true,
    pages: true,
    details: true,
    team: true,
    signupFormEnabled: true,
    signupFormName: "inscription-en-ligne",
    contactImg: "batiment_5088px.webp",
    avisImg: "cottonbro.webp",
    displayContactData: true,
  },
  ouvertureuape: {
    name: "Nouvel UAPE",
    short_name: "Nouvel UAPE",
    theme_color: "#1A73E8",
    emailAdress: "ouvertureuape@gmail.com",
    address: "La Mouniaz Centre, Route du Simplon 21, 1845 Noville",
    imagekitId: "vqawfgkaq",
    captchaKey: "6LcO_mwqAAAAAOPhrF-6qcLBhfkrw2HMwfNnO1WC",
    logo: "logoc.ico",
    customColor: "#A7C7E7",
    home: "Sondage", 
    signupFormName: "sondage",
    contactImg: "batiment_5088px.webp",
    avisImg: "cottonbro.webp",
    displayContactData: true,
    hideFooter: true,
  },
  maxicashback: {
    name: "maxicashback",
    short_name: "maxicashback",
    theme_color: "#1A73E8",
    emailAdress: "info@garderieauxpetitspas.ch",
    address: "La Mouniaz Centre, Route du Simplon 21, 1845 Noville",
    mapLink: "https://maps.app.goo.gl/yzQL3kjuBFcb2dPe9",
    phoneNumber: "+41 21 968 11 12",
    natel1Number: "+41 79 670 71 10",
    imagekitId: "o8gtczz93",
    captchaKey: "6LcO_mwqAAAAAOPhrF-6qcLBhfkrw2HMwfNnO1WC",
    logo: "logoc.ico",
    customColor: "#A7C7E7",
    home: "Presentation", 
    pages: true,
    signupFormEnabled: true,
    signupFormName: "sondage",
    contactImg: "batiment_5088px.webp",
    avisImg: "cottonbro.webp",
    displayContactData: true,
  },
  meilleurecrypto: {
    name: "La meilleure crypto",
    short_name: "MeilleureCrypto",
    theme_color: "#1A73E8",
    emailAdress: "lameilleurecrypto@gmail.com",
    author: "delaunayj", //Julien Delaunay
    imagekitId: "qzjdsrhh6",
    captchaKey: "6LfwmUsqAAAAAGOAI-9G1_039C7fqgi2IBj8JkIG",
    gtmEnabled: true,
    gtmId: "GTM-5FXP7JGB",
    logo: "logo-ct.ico",
    customColor: "#f0b90b",
    home: "Presentation", 
    showDesciptionInHome: true,
    showAuthor: true,
    information: true,
    posts: true,
    testimonials: true,
    affiliate: true,
    contactImg: "digital.webp",
    avisImg: "digital.webp",
  },
};
module.exports = siteConfig[process.env.REACT_APP_SITE];