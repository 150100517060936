import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MKTypography from "components/MKTypography";

// Créez le thème en important vos fichiers de style

// Données du tableau
const headers = [
  { label: "Tarifs", colSpan: 1 },
  { label: "Jour complet", subLabel: "6h30 – 18h30", colSpan: 1 },
  { label: "2/3 Jour", subLabel: "6h30 – 14h ou \n11h – 18h30", colSpan: 1 },
  { label: "1/2 Jour", subLabel: "6h30 – 11h ou \n14h – 18h30", colSpan: 1 },
];

const rows = [
  { day: "Lundi", fullDay: "122.-", twoThirdsDay: "104.50", halfDay: "63.-" },
  { day: "Mardi", fullDay: "122.-", twoThirdsDay: "104.50", halfDay: "63.-" },
  { day: "Mercredi", fullDay: "122.-", twoThirdsDay: "104.50", halfDay: "63.-" },
  { day: "Jeudi", fullDay: "122.-", twoThirdsDay: "104.50", halfDay: "63.-" },
  { day: "Vendredi", fullDay: "122.-", twoThirdsDay: "104.50", halfDay: "63.-" },
];

// Composant du tableau
const SimpleTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <TableContainer
      component={Paper}
      style={{ padding: isMobile ? "8px" : "16px", boxShadow: isMobile ? "unset" : undefined }}
    >
      {isMobile ? (
        <>
          {headers.slice(1).map((header, index) => (
            <>
              <MKTypography variant="h6" color="text">
                {header.label} :
              </MKTypography>

              {header.subLabel && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  {header.subLabel}
                </Typography>
              )}
            </>
          ))}

          <Box sx={{ m: 4 }} />
          {rows.map((row, index) => (
            // https://mui.com/material-ui/react-paper/
            <Paper key={index} style={{ marginBottom: "8px", padding: "8px" }}>
              <Typography variant="h6" component="div" style={{ fontWeight: "bold" }}>
                {row.day}
              </Typography>
              <Table aria-label="pricing table mobile">
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Jour complet</TableCell>
                    <TableCell align="right">{row.fullDay}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>2/3 Jour</TableCell>
                    <TableCell align="right">{row.twoThirdsDay}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>1/2 Jour</TableCell>
                    <TableCell align="right">{row.halfDay}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          ))}
        </>
      ) : (
        <Table aria-label="pricing table desktop">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  colSpan={header.colSpan}
                  align="center"
                  style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                >
                  {header.label}
                  {header.subLabel && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {header.subLabel}
                    </Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.day}</TableCell>
                <TableCell align="center">{row.fullDay}</TableCell>
                <TableCell align="center">{row.twoThirdsDay}</TableCell>
                <TableCell align="center">{row.halfDay}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
export default SimpleTable;
