export const content = `# Mentions Légales

Ce blog est édité par <span class="pseudo"></span>. Vous pouvez nous contacter à l'adresse e-mail suivante&nbsp;: <span class="email"></span>

## Hébergeur du site
Le site est hébergé par Netlify.  
Adresse&nbsp;: 44 Montgomery Street, Suite 300, San Francisco, California 94104, États-Unis.  
Site web&nbsp;: [https://www.netlify.com](https://www.netlify.com).

## Clause d'Affiliation
Ce site contient des liens d'affiliation. Cela signifie que nous pouvons recevoir une commission lorsque vous effectuez un achat via ces liens, sans frais supplémentaires pour vous. Nous sélectionnons les produits et services recommandés en fonction de leur qualité et de leur pertinence pour nos lecteurs.

## Responsabilité
Le contenu de ce blog est fourni à titre informatif. Nous ne garantissons pas l'exactitude des informations et ne pouvons être tenus responsables des conséquences liées à l'utilisation de ces informations.
`