import InputField from "./InputField";

const InputNpaField = ({ ...rest }) => {
  return (
    <InputField
      npa={true}
      type={"text"} // Utilise 'tel' pour mobile, 'text' pour desktop
      //   pattern={isMobile() ? "[0-9]*" : undefined} // Applique le pattern uniquement sur mobile
      //   inputMode={isMobile() ? "numeric" : undefined} // Utilise le clavier numérique uniquement sur mobile
      //   maxLength={isMobile() ? 4 : undefined}
      {...rest}
    />
  );
};

export default InputNpaField;
