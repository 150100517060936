/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  The hexToRgb() function helps you to change the hex color code to rgb
  using chroma-js library.
 */

// chroma-js is a library for all kinds of color conversions and color scales.
// it has been replaced by the following code because of its bundle size

function hexToRgb(color) {
  // Vérifier si le code couleur commence par un #
  if (color.startsWith("#")) {
    color = color.slice(1);
  }
  
  // Convertir le code hexadécimal en composantes RGB
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

export default hexToRgb;
