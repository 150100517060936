/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";
import PropTypes from "prop-types";
import MKInputRoot from "components/MKInput/MKInputRoot";

const MKInput = forwardRef(({ error, success, disabled, npa, phoneNumber, ...rest }, ref) => {
  const handleChange = (e) => {
    let { value } = e.target;

    if (npa || phoneNumber) {
      // Supprime tous les caractères non numériques et limite à 4 chiffres
      value = value.replace(/\D/g, "").slice(0, npa ? 4 : 10);

      // Ajoute un '0' en premier si phoneNumber et si le nombre ne commence pas par '0'
      if (phoneNumber && value.length > 0 && value[0] !== "0") {
        value = "0" + value;
      }
      
      e.target.value = value; // Met à jour explicitement la valeur de l'élément d'entrée
    }

    // Appelle la fonction onChange fournie par les props si elle existe
    if (rest.onChange) {
      rest.onChange(e);
    }
  };

  return (
    <MKInputRoot
      {...rest}
      ref={ref}
      ownerState={{ error, success, disabled }}
      onChange={handleChange}
    />
  );
});

// Setting default values for the props of MKInput
MKInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  npa: false, // Par défaut, npa est défini sur false
  phoneNumber: false, // Par défaut, npa est défini sur false
};

// Typechecking props for the MKInput
MKInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  npa: PropTypes.bool, // Définition de la prop npa
  phoneNumber: PropTypes.bool,
  onChange: PropTypes.func, // Pour permettre le passage d'une fonction onChange personnalisée
};

export default MKInput;
