// lib

import config from "config";
import { enableRecaptcha } from "./useRecaptcha";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z\u00C0-\u017F]{2,}$/;

export const validateEmail = (email) => emailRegex.test(email);

export const validatePhoneNumber = (number, errors, key) => {
  let error;
  if (!number) error = "Téléphone requis";
  if (!isMobile() && (isNaN(number) || number.length !== 10)) error = "Merci de saisir un numéro de téléphone de 10 chiffres";
  if (error) {
    errors[key] = error;
  }
}

export const validateNpa = (npa, errors, key) => {
  const error = !npa ? "NPA requis" : (isNaN(npa) || npa.length !== 4) ? "Merci de saisir un NPA de 4 chiffres" : undefined;
  if (error) {
    errors[key] = error;
  }
}

export const isMobile = () => {
  return /android|iphone|ipad|ipod/i.test(navigator.userAgent);
}

export const tomorrow = () => {
  const date =  new Date();
  date.setDate(date.getDate() + 1);
  return date;
}

export const encodeFormData = (data) => {
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`; // Transforme en DD.MM.YYYY
  };

  return Object.keys(data).map((key) => {
    let value = data[key];
    if (key.startsWith("date")) {
      value = formatDate(value); // Formate les dates
    }
    return encodeURIComponent(key) + "=" + encodeURIComponent(value);
  }).join("&");
};

export const callbackAfterRecaptcha = (callback) => {
  if (enableRecaptcha) {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(config.captchaKey, { action: "contact_form" })
        .then(function (token) {
          fetch("/.netlify/functions/verify-recaptcha", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ token }),
          })
            .then((response) => response.json())
            .then((data) => {
              // console.log("recaptcha", data);
              if (data.message === "Verification successful!") {
                callback();
              } else {
                // console.log("Échec de la vérification reCAPTCHA.");
              }
            })
            .catch((error) => console.error("Erreur lors de la vérification:", error));
        });
    });
  } else {
    callback();
  }
}

export const getImage = (filename, purge = false, width) => {
    // URL de base
    let url = `https://ik.imagekit.io/${config.imagekitId}/images/${filename}`;
  
    // Création d'un tableau pour stocker les paramètres de la query string
    let params = [];
  
    // Si width est défini, ajoute le paramètre de taille
    if (width) {
      params.push(`tr=w-${width}`);
    }
  
    // Si purge est true, ajoute un timestamp pour forcer l'actualisation
    if (purge) {
      params.push(`version=${Date.now()}`);
    }
  
    // Si des paramètres existent, les ajouter à l'URL
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
  
    return url;
  };

export const buildEmailLink = (emailAdress) => <a href={`mailto:${emailAdress}`}>{emailAdress}</a>

export const buildPhoneLink = (phone) => <a href={`tel:${phone}`}>{phone}</a>
